import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useEffect, useState } from "react"
import { bannannagram, cashback, doughboys, f45, foodtruck, honeymoon, kasian, wayside, personpicture } from '../assets'
import { signInAnonymously, getEmailDocument, getStats } from "../utils/api"
import { Dialog, Transition, Popover } from '@headlessui/react'
import { submitEmailToWaitlist } from '../utils/api'
import { Link } from 'react-router-dom'
import '../styles/background.css'
import Waitlist from "../components/Waitlist"

const navigation = [
    // { name: 'Want to Advertise?', href: '#' },
]

export default function HomeScreen() {

    const [email, setEmail] = useState()
    const [submitted, setSubmitted] = useState(false)
    const [emailDocumentId, setEmailDocumentId] = useState('')
    const [documentData, setDocumentData] = useState('')
    const [position, setPosition] = useState(0)

    useEffect(() => {
        (async () => {
            return signInAnonymously()
        })();
    }, [])

    // useEffect(() => {
    //     (async () => {
    //         if(emailDocumentId !== ''){
    //             return getEmailDocument(emailDocumentId, setDocumentData)
    //         }
    //     })();
    // }, [emailDocumentId])

    // useEffect(() => {
    //     (async () => {
    //         if (documentData.position) {
    //             setPosition(documentData.position)
    //         }
    //     })();
    // }, [documentData])

    function addEmailToWaitlist(e) {
        e.preventDefault()
        // change this to navigate to a different page for the waitlist page
        return submitEmailToWaitlist(email).then(async ref => {
            // setEmailDocumentId(ref.id)
            let count = await getStats()
            setPosition(count)
            setSubmitted(true)
        })
    }

    return (
        <div>
            {/* background */}
            <div class="background">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div className="relative pt-6 pb-16 sm:pb-24">
                <Popover>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6">
                        <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
                            <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                                <div className="flex items-center justify-between w-full md:w-auto">
                                    <Link to='/'>
                                        <span className="sr-only">Workflow</span>
                                        <div className='flex flex-row items-center'>
                                            <FontAwesomeIcon icon='heart' size='2x' className='text-red-500 mr-3' />
                                            <h1 className='text-gray-900'>Georgi</h1>
                                        </div>
                                    </Link>
                                    <div className="-mr-2 flex items-center md:hidden">
                                        <Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
                                            <span className="sr-only">Open main menu</span>
                                            {/* <MenuIcon className="h-6 w-6" aria-hidden="true" /> */}
                                            <FontAwesomeIcon icon='bars' className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:flex md:space-x-10">
                                {navigation.map((item) => (
                                    <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </nav>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-150 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                        >
                            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="px-5 pt-4 flex items-center justify-between">
                                    <div>
                                        <div className='flex flex-row items-center'>
                                            <FontAwesomeIcon icon='heart' size='2x' className='text-red-500 mr-3' />
                                            <h1 className='text-gray-900'>Georgi</h1>
                                        </div>
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
                                            <span className="sr-only">Close menu</span>
                                            {/* <XIcon className="h-6 w-6" aria-hidden="true" /> */}
                                            <FontAwesomeIcon icon='times' className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>
                                <div className="px-2 pt-2 pb-3">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>
            </div>

            <div>
                <main>
                    {/* Hero section */}
                    <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48 grid grid-cols-1 row-end-2">
                        <div className="mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                            <div>
                                {submitted ? <Waitlist position={position} setPosition={setPosition} id={emailDocumentId} /> :

                                    <div className="mt-20">
                                        <div className="mt-6">
                                            <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl text-center">
                                                👋 Wanna be an Influencer?
                                            </h1>
                                            <p className="mt-6 text-xl text-gray-500 text-center">
                                                Get paid to post content, regardless of your following.
                                            </p>
                                        </div>
                                        <form onSubmit={e => addEmailToWaitlist(e)} className="mt-24 sm:max-w-lg sm:w-full sm:flex text-center" id='first'>
                                            <div className="min-w-0 flex-1">
                                                <label htmlFor="hero_email" className="sr-only">
                                                    Email address
                                                </label>
                                                <input
                                                    id="hero_email"
                                                    type="email"
                                                    className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-red-500 focus:ring-red-500"
                                                    placeholder="Enter your email"
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className="mt-4 sm:mt-0 sm:ml-3">
                                                <button
                                                    type="submit"
                                                    className="block w-full rounded-md border border-transparent px-5 py-3 bg-red-500 text-base font-medium text-white shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:px-10"
                                                >
                                                    Join Private Beta
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main>

                {/* Footer section */}
                <footer className="">
                    <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">

                        </nav>
                        <div className="mt-8 flex justify-center space-x-6">

                        </div>
                        <p className="mt-8 text-center text-base text-gray-400">&copy; 2021 Georgi, Inc. All rights reserved.</p>
                    </div>
                </footer>
            </div>
        </div>
    )
}