import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useEffect, useState } from "react"
import { bannannagram, cashback, doughboys, f45, foodtruck, honeymoon, kasian, wayside, personpicture } from '../assets'
import { signInAnonymously } from "../utils/api"
import { Dialog, Transition, Popover } from '@headlessui/react'
import { submitEmailToWaitlist } from '../utils/api'
import { Link } from 'react-router-dom'
import '../styles/background.css'
import AnimatedNumber from "animated-number-react";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookMessengerShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    LinkedinIcon,
    PinterestIcon,
    RedditIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,

} from "react-share";


export default function Waitlist({ position, setPosition, id }) {


    const formatValue = (value) => value.toFixed(0);

    const shareUrl = `https://georgi.app/${id}`
    const title = 'Wanna be an influencer too? Get paid to post content, regardless of your following.'

    const [enabled, setEnabled] = useState({
        sms: true,
        facebook: true,
        email: true,
        linkedIn: true,
        twitter: true,
        whatsapp: true
    })

    const onClose = (platform) => {
        var newObj = { ...enabled }
        if(newObj[platform]){
            setPosition(position - 100)
        }
        newObj[platform] = false
        setEnabled(newObj)
        
    }

    return (
        <div className='text-center'>
            <div>
                <p className='text-lg text-gray-500'>There are currently</p>
                <AnimatedNumber
                    value={position}
                    formatValue={formatValue}
                    className='text-5xl'
                />
                <p className='text-lg text-gray-500'>people ahead of you</p>
            </div>
            <div className='mt-12'>
                <p className='text-lg text-gray-500' >Jump ahead 100 people each time you share us</p>
                {/* react share buttons */}
                <div className='flex flex-row justify-center'>
                    <a
                        className={`flex justify-center center ${!enabled['sms'] ? 'is-disabled' : ''}`}
                        href={`sms:&body=Wanna be an influencer too? \n Checkout Georgi, where you can get paid to post content regardless of your following? \n\n ${shareUrl}`}
                        onClick={() => onClose('sms')}
                    >
                        <div className='bg-green-400 p-1 pl-2 pr-2 rounded-3xl'>

                            <FontAwesomeIcon icon='phone' color='white' />
                        </div>
                    </a>

                    <EmailShareButton
                        onClick={() => onClose('email')}
                        disabled={!enabled['email']}
                    >
                        <EmailIcon size={32} round />
                    </EmailShareButton>
                    <FacebookShareButton
                        url={shareUrl}
                        quote={title}
                        className="Demo__some-network__share-button"
                        onShareWindowClose={() => onClose('facebook')}
                        disabled={!enabled['facebook']}
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>

                    <LinkedinShareButton
                        url={shareUrl}
                        className="Demo__some-network__share-button"
                        onShareWindowClose={() => onClose('linkedIn')}
                        disabled={!enabled['linkedIn']}
                    >
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>

                    <TwitterShareButton
                        url={shareUrl}
                        title={title}
                        className="Demo__some-network__share-button"
                        onShareWindowClose={() => onClose('twitter')}
                        disabled={!enabled['twitter']}
                    >
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>

                    <WhatsappShareButton
                        url={shareUrl}
                        title={title}
                        separator=":: "
                        className="Demo__some-network__share-button"
                        onShareWindowClose={() => onClose('whatsapp')}
                        disabled={!enabled['whatsapp']}
                    >
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    
                </div>
            </div>


            <div className='mt-12'>
                <p className='text-lg text-gray-500'>Follow us to stay up to date</p>
                {/* links to social media sites */}
                <a href="https://www.instagram.com/georgi_influencer/" target="_blank" rel="noopener noreferrer" className='flex justify-center center'>
                    <div className='bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 p-1 pl-2 pr-2 rounded-3xl'>
                        <FontAwesomeIcon icon={['fab', 'instagram']} color='white' />
                    </div>
                </a>
            </div>
        </div>
    )
}