import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import './App.css';
import HomeScreen from '../src/screens/HomeScreen'

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/'>
          <HomeScreen />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
