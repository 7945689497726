import firebase from './firebase'

export async function submitEmailToWaitlist(email) {
    if (!firebase.auth().currentUser) {
        return;
    }
    return firebase.firestore().collection('waitlist').add({
        email
    }).catch(e => console.error(e))
}

export async function submitEmailToContact(email) {
    if (!firebase.auth().currentUser) {
        return;
    }
    return firebase.firestore().collection('contact').add({
        email
    }).catch(e => console.error(e))
}


export async function signInAnonymously() {
    if (!firebase.auth().currentUser) {
        return firebase.auth().signInAnonymously().catch(e => console.error(e))
    }
}

// export async function getEmailDocument(documentId, callback) {
//     return firebase.firestore().collection('waitlist').doc(documentId).onSnapshot(snap => {
//         callback(snap.data)
//     }, e => console.error(e))
// }

export async function getStats(){
    return firebase.firestore().collection('stats').doc('waitlist').get().then(res => {
        let data = res.data()
        return data.count
    })
}