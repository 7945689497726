import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faCheck, faHamburger, faHeart, faMoneyBillWave, faPhone, faStar, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

//https://fontawesome.com/how-to-use/on-the-web/using-with/react

//have to add every icon name here before it can be seen in the site

export default function AddIcons(){

    library.add(
        faMoneyBillWave,
        faStar,
        faCheck,
        faHeart, 
        faTimes,
        faBars, 
        faPhone,
        faInstagram
        )
}